import { Col, Container, Row } from 'react-bootstrap';
import pkg from '../../package.json';

export const Download = () => (
  <section id="download" className="image-section">
    <div className="image-overlay"></div>

    <Container className="image-section-inside">
      <Row className="justify-content-md-center">
        <Col md={10} className="text-center margin-top-110 margin-bottom-140">
          <h2 className="large-title white">Seen enough? Let's fly!</h2>

          <p className="section-subtitle white">
            Grab it for free during the Beta phase and enjoy it at no cost for a lifetime!
          </p>

          <a href={pkg.downloadUrl} target="_blank" className="btn btn-lg btn-blue margin-top-20 scrool">
            TRY IT FOR FREE!
          </a>
        </Col>
      </Row>
    </Container>
  </section>
);
