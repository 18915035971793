import PassCargoImage from 'assets/images/passcargo-active-flight.png';
import pkg from '../../package.json';
/*

<iframe
            width="560"
            height="315"
            src="https://www.youtube.com/embed/aMoQOwv9MFM?si=3NFALCT_mucfwwxp"
            title="YouTube video player"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
            allowFullScreen
          ></iframe>
          */

export const Banner = () => (
  <section id="home" className="home-wrapper">
    <div className="gradient_overlay"></div>
    <div className="container home-wrappe-inside">
      <div className="row margin-bottom-30">
        <div className="col-md-6 padding-top-20">
          <h1 className="home-title wow fadeIn" data-wow-delay="0.5s">
            Are you prepared for an exciting new experience?
          </h1>

          <p className="home-subtitle wow fadeIn" data-wow-delay="1s">
            - Dispatch your flight plan using Simbrief
            <br />
            - Seamlessly import your flight plan from Simbrief
            <br />- Access a live map to connect with fellow pilots in our system
          </p>

          <a
            href={pkg.downloadUrl}
            target="_blank"
            className="btn btn-lg btn-blue scrool wow fadeIn"
            data-wow-delay="1.5s"
          >
            Download PassCargo Desktop
          </a>
        </div>
        <div className="col-md-6 wow slideInRight" data-wow-delay="2s">
          <img src={PassCargoImage} alt="PassCargo Active Flight" width="80%" />
        </div>
      </div>
    </div>
  </section>
);
