import { Col, Container, Row } from 'react-bootstrap';

export const Overlay = () => (
  <section id="overlay">
    <div className="section-white small-padding-bottom">
      <Container>
        <Row className="margin-bottom-30">
          <Col md={12} className="text-center">
            <h2 className="section-title">Stream Overlay</h2>

            <div className="separator_wrapper">
              <i className="icon icon-star-two red"></i>
            </div>

            <p className="section-subtitle">
              PassCargo Overlay allows you to display positon and state of the aircraft/flight
            </p>
          </Col>
        </Row>
        <Row>
          <Col sm={4} className="features-item">
            <div className="icon-wrapper">
              <i className="icon icon-settings-streamline-2 features-icon"></i>
            </div>

            <h3>Fully Customizable</h3>

            <p>You can select which information could be displayed as well as scale, colors, fonts, etc.</p>
          </Col>
          <Col sm={4} className="features-item">
            <div className="icon-wrapper">
              <i className="icon icon-gamepad features-icon"></i>
            </div>

            <h3>Popular Flight Simulators</h3>

            <p>PassCargo supports Microsoft Flight Simulator 2020 only, X Plane 11/12 and Prepar3D.</p>
          </Col>
          <Col sm={4} className="features-item">
            <div className="icon-wrapper">
              <i className="icon icon-computer-streamline features-icon"></i>
            </div>

            <h3>Streaming Software</h3>

            <p>
              The overlay is accessible as a web endpoint, making it usable with any browser-compatible plugin for
              streaming software like OBS Studio.
            </p>
          </Col>
        </Row>
      </Container>
    </div>
  </section>
);
