import Logo from 'assets/images/passcargo-logo-64.png';
import { Col, Container, Row } from 'react-bootstrap';
import { Link } from 'react-router-dom';

export const Footer = () => (
  <div className="footer">
    <Container>
      <Row>
        <Col md={12} className="text-center">
          <div className="copyright">
            <p className="footer-logo">
              <img src={Logo} alt="PassCargo" />
            </p>
            <p>
              Copyright © 2024. Proudly created by{' '}
              <a href="https://flysim.io" target="_blank" rel="noreferrer">
                FlyAround Simulations
              </a>{' '}
              in Atlanta, USA
            </p>
          </div>
          <ul className="footer_social">
            <li>
              <a href="https://x.com/flyaroundtv" target="_blank" rel="noreferrer">
                <i className="icon icon-twitter"></i>
              </a>
            </li>
            <li>
              <a href="https://www.facebook.com/groups/flyaround" target="_blank" rel="noreferrer">
                <i className="icon icon-facebook"></i>
              </a>
            </li>
            <li>
              <a href="https://www.instagram.com/flyaroundtv" target="_blank" rel="noreferrer">
                <i className="icon icon-instagram"></i>
              </a>
            </li>
            <li>
              <a href="https://www.youtube.com/flyaroundtv" target="_blank" rel="noreferrer">
                <i className="icon icon-youtube"></i>
              </a>
            </li>
          </ul>
        </Col>
      </Row>
      <Row>
        <Col md={12} className="text-center mt-3">
          <div className="copyright">
            <p>
              <Link to="/privacy">Privacy Policy</Link>
              &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
              <Link to="/tos">Terms of Use</Link>
            </p>
          </div>
        </Col>
      </Row>
    </Container>
  </div>
);
