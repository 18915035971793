import React from 'react';
import ReactDOM from 'react-dom/client';
import App from './App';
import 'bootstrap/dist/css/bootstrap.css';
import 'assets/scss/style.scss';
import 'assets/scss/fonts.scss';
import 'assets/scss/custom.scss';

const root = ReactDOM.createRoot(document.getElementById('main') as HTMLElement);
root.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>,
);
