import overlayImage from 'assets/images/passcargo-active-flight.png';
import vscodeImage from 'assets/images/passcargo-live-map.png';
import { Col, Container, Row } from 'react-bootstrap';

export const LatestNews = () => (
  <section className="section-white" id="news">
    <Container>
      <Row className="margin-bottom-50 justify-content-md-center">
        <Col md={10} className="text-center">
          <h2 className="section-title">Our Latest News</h2>

          <div className="separator_wrapper">
            <i className="icon icon-star-two blue"></i>
          </div>

          <p className="section-subtitle">Work-in-progress updates and release news.</p>
        </Col>
      </Row>

      <Row>
        <Col sm={4}>
          <div className="blog-item">
            <div className="popup-wrapper">
              <div className="popup-gallery">
                <img src={overlayImage} className="width-100" alt="pic" />
              </div>
            </div>

            <div className="blog-item-inner">
              <h3 className="blog-title">PassCargo Desktop Revisited</h3>

              <p>
                We’re excited to introduce our PassCargo Desktop application! It has been completely redesigned using
                FSUIPC/XPUIPC, eliminating the need for FSUIPC WebSocket. Enjoy a sleek new UI and streamlined
                processes!
              </p>
            </div>
          </div>
        </Col>
        <Col sm={4}>
          <div className="blog-item">
            <div className="popup-wrapper">
              <div className="popup-gallery">
                <img src={vscodeImage} className="width-100" alt="pic" />
              </div>
            </div>

            <div className="blog-item-inner">
              <h3 className="blog-title">Live Map</h3>

              <p>Connect with fellow pilots in our system and share your flights too!</p>
            </div>
          </div>
        </Col>
        <Col sm={4}>
          <div className="blog-item">
            <div className="popup-wrapper">
              <div className="popup-gallery">
                <img src={vscodeImage} className="width-100" alt="pic" />
              </div>
            </div>

            <div className="blog-item-inner">
              <h3 className="blog-title">PassCargo Scoring</h3>

              <p>We are currently working on scoring updates and many other exciting features.</p>
            </div>
          </div>
        </Col>
      </Row>
    </Container>
  </section>
);
