import { Col, Container, Row } from 'react-bootstrap';

export const Desktop = () => (
  <section id="desktop">
    <div className="section-grey small-padding-bottom">
      <Container>
        <Row className="margin-bottom-30">
          <Col md={12} className="text-center">
            <h2 className="section-title">PassCargo Desktop</h2>

            <div className="separator_wrapper">
              <i className="icon icon-star-two red"></i>
            </div>

            <p className="section-subtitle">
              Track your flight in Microsoft Flight Simulator 2020, X Plane 11/12, Prepar3D
            </p>
          </Col>
        </Row>
        <Row>
          <Col sm={4} className="features-item">
            <div className="icon-wrapper">
              <i className="icon icon-settings-streamline-2 features-icon"></i>
            </div>

            <h3>Integrations</h3>

            <p>Simbrief is set up, and more integrations are on the way!</p>
          </Col>
          <Col sm={4} className="features-item">
            <div className="icon-wrapper">
              <i className="icon icon-gamepad features-icon"></i>
            </div>

            <h3>MSFS 2020, XP 11/12, P3D</h3>

            <p>Choose your preferred simulator and launch your next flight with PassCargo!</p>
          </Col>
          <Col sm={4} className="features-item">
            <div className="icon-wrapper">
              <i className="icon icon-windows features-icon"></i>
            </div>

            <h3>Windows Only</h3>

            <p>Linux and macOS versions will be available by the end of this year.</p>
          </Col>
        </Row>
      </Container>
    </div>
  </section>
);
