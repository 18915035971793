import Logo from 'assets/images/passcargo-logo-64.png';
import { Container, Nav, Navbar } from 'react-bootstrap';

export const HeaderBox = () => (
  <Navbar sticky="top" className="navbar-default" expand="lg">
    <Container>
      <Navbar.Toggle aria-controls="responsive-navbar-nav" />
      <Navbar.Brand href="/" className="brand scrool">
        <img src={Logo} alt="PassCargo" width="48" /> PassCargo
      </Navbar.Brand>
      <Navbar.Collapse id="responsive-navbar-nav" className="justify-content-end">
        <Nav as="ul">
          <Nav.Item as="li">
            <Nav.Link href="#home" data-to-scrollspy-id="home">
              Home
            </Nav.Link>
          </Nav.Item>
          <Nav.Item as="li">
            <Nav.Link href="#desktop" data-to-scrollspy-id="desktop">
              Desktop
            </Nav.Link>
          </Nav.Item>
          <Nav.Item as="li">
            <Nav.Link href="#overlay" data-to-scrollspy-id="overlay">
              Stream Overlay
            </Nav.Link>
          </Nav.Item>
          <Nav.Item as="li">
            <Nav.Link href="#download" data-to-scrollspy-id="download">
              Download
            </Nav.Link>
          </Nav.Item>
          <Nav.Item as="li">
            <Nav.Link href="//app.passcargo.app" className="purchase scrool">
              Get App
            </Nav.Link>
          </Nav.Item>
        </Nav>
      </Navbar.Collapse>
    </Container>
  </Navbar>
);
